<template>
    <div class="loader-wrapper" v-show="isLoading">
        <loader></loader>
    </div>
    <div class="v1-bloc" id="v1-bloc">
        <v3-warning />
        <div class="ZoneDonnee" v-html="htmlResponse"></div>
    </div>
</template>

<script>
import httpIntratone from "@/services/httpIntratone.js"
import Loader from "@/components/basic/Loader.vue"
import { nextTick } from "@vue/runtime-core"

import { useToast } from "vue-toastification"
import store from "@/store"
import V3Warning from "@/components/V3Warning"
const toast = useToast()

export default {
    name: "V1",
    components: {
        V3Warning,
        Loader,
    },
    store,
    data() {
        return {
            // URL for request
            pathURL: null,
            // Data for request
            pathData: null,
            // #ZoneDonee inner HTML (main)
            htmlResponse: null,
            // Name of zone where HTML should be displayed
            vZone: null,
            // Show loader
            isLoading: false,
        }
    },
    computed: {
        // Splited arguments from v1 route
        pathArguments() {
            return this.$route.path.split("/").slice(2)
        },
    },
    created() {
        this.setRequestValues()
        this.loadData()
    },
    watch: {
        $route() {
            if (this.$route.name.startsWith("V1")) {
                this.setRequestValues()
                this.loadData()
            }

            // TODO :: à retirer quand Gérer / Rechercher sera en full Vue
            if (this.$route.fullPath.startsWith("/v1/data/search/load/res=")) {
                let param = this.$route.params.arg4
                param = param.replace("res=", "")
                this.$store.commit("account/setResidenceId", parseInt(param))
            }
        },
    },
    methods: {
        async loadData() {
            try {
                // TEMP
                let dataWithSID =
                    this.pathData !== null ? `${this.pathData}&` : ""
                dataWithSID += `SID=${this.$store.getters["auth/getIntratoneDev2SID"]}`

                // Showing loder
                this.isLoading = true

                // Requesting v1 site for content
                let v1Response = null
                // print (GET request)
                if (this.pathArguments[0] === "print") {
                    v1Response = await httpIntratone.get(
                        this.pathURL + "?" + dataWithSID
                    )
                    // data (POST request)
                } else {
                    v1Response = await httpIntratone.post(
                        this.pathURL,
                        dataWithSID
                    )
                }
                if (v1Response.status !== 200) {
                    throw new Error()
                }

                // Error control
                if (v1Response.data.err) {
                    toast.warning(
                        this.$t("alerts.errors." + v1Response.data.err),
                        {
                            icon: "fas fa-exclamation-circle",
                        }
                    )
                    if (
                        v1Response.data.js !== "" &&
                        v1Response.data.js !== null &&
                        v1Response.data.js !== undefined
                    ) {
                        eval(v1Response.data.js)
                    } else {
                        history.back()
                    }
                }

                // Hidding loader
                this.isLoading = false

                // Appending HTML response
                if (
                    this.pathArguments[0] === "data" &&
                    this.pathArguments[1] === "XmlToHtml"
                ) {
                    // Specific case : XmlToHtml
                    this.htmlResponse = v1Response.data
                    await nextTick()
                    // plages
                    eval(
                        "$('#PlageH').show();$('#JourFerie').hide();Plages();$(this).parent().find('.select').removeClass('select'); $(this).addClass('select');"
                    )
                } else {
                    this.htmlResponse = v1Response.data.html
                }

                // Waiting for DOM to be updated
                await nextTick()

                // Appending and executing JS response
                if (v1Response.data.js !== "") {
                    let script = document.createElement("script")
                    script.text = v1Response.data.js
                    document.head.appendChild(script)
                    // eval(v1Response.data.js)
                }

                // Change back button in order to actually go back in history
                if (!window.history.state.back.startsWith("/renew")) {
                    for (let element of document.getElementsByClassName(
                        "precedent"
                    )) {
                        element.onclick = function () {
                            history.back()
                        }
                    }
                }

                // Resetting resquest values
                this.resetRequestValues()
            } catch (error) {
                console.log(error)
            }
        },
        setRequestValues() {
            let pathArguments = [...this.pathArguments]
            if (pathArguments[pathArguments.length - 1].includes("=")) {
                this.pathData = pathArguments
                    .pop()
                    .replaceAll("§", "/")
                    .replaceAll("%C2%A7", "/")
                if (this.pathData.includes("vzone")) {
                    this.vZone = this.pathData
                        .split("&")
                        .find((element) => element.substring(0, 5) === "vzone")
                        .split("=")[1]
                } else {
                    this.vZone = null
                }
            }
            this.pathURL = pathArguments.join("/") + ".php"
        },
        resetRequestValues() {
            this.pathURL = null
            this.pathData = null
            this.vZone = null
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";

.v1-bloc :deep() {
    @import "./../assets/scss/v1-scss/style.scss";
    @import "./../assets/scss/v1-scss/style_v4.scss";
    @import "./../assets/scss/v1-scss/datepicker.scss";
    @import "./../assets/scss/v1-scss/cle.scss";
    @import "./../assets/scss/v1-scss/templates-lora.scss";

    @import "@/assets/scss/v1-scss/cropper.scss";
}

.loader-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.v1-bloc {
    height: 100%;
    overflow: auto;
    @media all and (max-width: 1024px) {
        padding: 30px 30px 40px 50px;
    }
    @media all and (min-width: 1024px) {
        padding: 30px 30px 0 50px;
    }
}

.v1-bloc :deep(a) {
    color: black;
}

.v1-bloc :deep(.container) {
    padding: 0;
    width: auto;
}

@media print {
    .animated {
        -webkit-animation: unset !important;
        animation: unset !important;
        -webkit-transition: none !important;
        transition: none !important;
    }
}
</style>
